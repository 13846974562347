<template>
  <div class="p-wrapper mobile">
    <div class="p-content">
      <div class="markdown-body">
        <h1 class="title"><strong>第三方SDK目录</strong></h1>
        <p>华为渠道SDK</p>
        <p>使用目的：接入账号系统和使用支付功能</p>
        <p>官网链接：</p>
        <p>
          <a href="https://developer.huawei.com/consumer/cn/doc/development/AppGallery-connect-Library/agc-auth-android-sdkdownload-0000001058498518">https://developer.huawei.com/consumer/cn/doc/development/AppGallery-connect-Library/agc-auth-android-sdkdownload-0000001058498518</a>
        </p>
        <br/><br/>
        <p>vivo渠道SDK</p>
        <p>使用目的：接入账号系统和使用支付功能</p>
        <p>官网链接：<a
            href="https://dev.vivo.com.cn/documentCenter/doc/6">https://dev.vivo.com.cn/documentCenter/doc/6</a></p>
        <br/><br/>
        <p>百度渠道SDK</p>
        <p>使用目的：接入账号系统和使用支付功能</p>
        <p>官网链接：<a href="https://g.baidu.com/gpbusiness/#/index">https://g.baidu.com/gpbusiness/#/index</a></p>
        <br/><br/>
        <p>应用宝渠道SDK</p>
        <p>使用目的：接入账号系统和使用支付功能、使用分享功能</p>
        <p>官网链接：<a href="https://wiki.open.qq.com/index.php?title=YSDK%E4%B8%8B%E8%BD%BD">https://wiki.open.qq.com/index.php?title=YSDK%E4%B8%8B%E8%BD%BD</a>
        </p>
        <br/><br/>
        <p>小米渠道SDK</p>
        <p>使用目的：接入账号系统和使用支付功能</p>
        <p>官网链接：<a href="https://dev.mi.com/console/doc/detail?pId=102">https://dev.mi.com/console/doc/detail?pId=102</a>
        </p>
        <br/><br/>
        <p>魅族渠道SDK</p>
        <p>使用目的：接入账号系统和使用支付功能</p>
        <p>官网链接：<a href="http://open-wiki.flyme.cn/doc-wiki/index?title=SDK%E6%8E%A5%E5%85%A5%E6%89%8B%E5%86%8C">http://open-wiki.flyme.cn/doc-wiki/index?title=SDK%E6%8E%A5%E5%85%A5%E6%89%8B%E5%86%8C</a>
        </p>
        <br/><br/>
        <p>OPPO渠道SDK</p>
        <p>使用目的：接入账号系统和使用支付功能</p>
        <p>官网链接：<a href="https://open.oppomobile.com/wiki/doc#id=10470">https://open.oppomobile.com/wiki/doc#id=10470</a>
        </p>
        <br/><br/>
        <p>联想渠道SDK</p>
        <p>使用目的：接入账号系统和使用支付功能</p>
        <p>官网链接：<a href="http://open.lenovomm.com/">http://open.lenovomm.com/</a></p>
        <br/><br/>
        <p>九游渠道SDK</p>
        <p>使用目的：接入账号系统和使用支付功能</p>
        <p>官网链接：<a href="https://aligames.open.uc.cn/document/doc/detail/15">https://aligames.open.uc.cn/document/doc/detail/15</a>
        </p>
        <br/><br/>
        <p>Bilibili渠道SDK</p>
        <p>使用目的：接入账号系统和使用支付功能</p>
        <p>
          官网链接：<a
            href="https://open.biligame.com/docs/#/view/5e29ad10ba1211ec836ad69817578ac6/65070460c05311ecb9fafea22d17cf6a">https://open.biligame.com/docs/#/view/5e29ad10ba1211ec836ad69817578ac6/65070460c05311ecb9fafea22d17cf6a</a>
        </p>
        <p>手Q渠道SDK</p>
        <p>使用目的：接入账号系统和使用支付功能</p>
        <p>官网链接：<a href="https://game.qq.com/contract.shtml">https://game.qq.com/contract.shtml</a></p>
        <br/><br/>
        <p>微信渠道SDK</p>
        <p>使用目的：接入账号系统和使用支付功能，分享功能</p>
        <p>官网链接：<a href="https://pay.weixin.qq.com/wiki/doc/apiv3/wxpay/pages/index.shtml">https://pay.weixin.qq.com/wiki/doc/apiv3/wxpay/pages/index.shtml</a>
        </p>
        <br/><br/>
        <p>支付宝支付SDK</p>
        <p>使用目的：使用支付功能</p>
        <p>官网链接：<a href="https://opendocs.alipay.com/open/204">https://opendocs.alipay.com/open/204</a></p>
        <br/><br/>
        <p>支付宝h5支付SDK</p>
        <p>使用目的：使用支付功能</p>
        <p>官网链接：<a href="http://myjsapi.alipay.com/jsapi/">http://myjsapi.alipay.com/jsapi/</a></p>
        <br/><br/>
        <p>QQ分享SDK</p>
        <p>使用目的：使用分享功能</p>
        <p>官网链接：<a href="https://wiki.connect.qq.com/sdk%e4%b8%8b%e8%bd%bd">https://wiki.connect.qq.com/sdk%e4%b8%8b%e8%bd%bd</a>
        </p>
        <br/><br/>
        <p>神州付SDK</p>
        <p>使用目的：使用支付功能</p>
        <p>官网链接：<a href="https://cardmaster.shenzhoufu.com/">https://cardmaster.shenzhoufu.com/</a></p>
        <br/><br/>
        <p>udesk客服SDK</p>
        <p>使用目的：收集玩家问题</p>
        <p>官网链接：<a href="https://www.udesk.cn/apply-o2o.html">https://www.udesk.cn/apply-o2o.html</a></p>
        <br/><br/>
        <p>极验一键登录SDK</p>
        <p>使用目的：方便玩家登录</p>
        <p>官网链接：<a href="https://www.geetest.com/">https://www.geetest.com/</a></p>
        <br/><br/>
        <p>Bugly异常信息收集SDK</p>
        <p>使用目的：收集APP崩溃信息</p>
        <p>官网链接：<a href="https://bugly.qq.com/v2/downloads">https://bugly.qq.com/v2/downloads</a></p>
        <br/><br/>
        <p>银联支付SDK</p>
        <p>使用目的：使用支付功能</p>
        <p>官网链接：<a href="https://cn.unionpay.com/upowhtml/cn/templates/index/index.html">https://cn.unionpay.com/upowhtml/cn/templates/index/index.html</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/privacy.scss";

p {
  text-indent: 0;
}
</style>

